import { useState } from "react";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";

import { useAuth } from "../../context/auth";

// import { useFirebase } from "react-redux-firebase";
import { useTranslation } from "react-i18next";

import { persistedUser, removeTokenID } from "../../store/persistedHelper";

import * as ROUTES from "../../constants/routes";
import GoogleSign from "./GoogleSignin";
import { UserLogin } from "src/constants/types/User";

type ErrorMessage = {
  message?: string;
};
const initState = {
  email: "",
  password: "",
  error: { message: "" },
};

const SignInPage = (props: any) => {
  const { t } = useTranslation();
  return (
    <div className="Container__Sign">
      <h1 className="f-subTitle">{t("signin.title")}</h1>
      <SignInForm {...props} />
    </div>
  );
};

const SignInForm = (props: any) => {
  const [state, setState] = useState<UserLogin>(initState);
  const [error, setError] = useState<ErrorMessage>({
    message: "",
  });
  // const { dispatch } = useContext(userContext);
  const { signIn, requestForgetPwd } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChange = (ev: any) => {
    setState({ ...state, [ev.target.name]: ev.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // if (firebase.auth().currentUser) firebase.auth().signOut();
    try {
      const { user } = await signIn(state) || {};
      // if (_currentUser.user) {
      //   manageUserConnection(_currentUser);
      // }
      //   !error && currentUser && (await getUserById(currentUser?.user?.uid));

      !props.onStep ? user && navigate(ROUTES.HOME) : props.setStep();
    } catch (err) {
      console.log("error on log in===", err);
      removeTokenID();
      if (err) {
        setError(err);
      }
    }

    setState({ ...initState });
  };

  // render(){
  const { password, email } = state;

  var isInvalid = password === "" || email === "";
  return (
    <Form onSubmit={handleSubmit} className="Form Form__Signin">
      <Form.Group>
        <Form.Control
          type="text"
          name="email"
          placeholder="email"
          onChange={onChange}
          value={email}
        />
        <Form.Control
          type="password"
          name="password"
          placeholder="password"
          onChange={onChange}
          value={password}
        />
      </Form.Group>
      <div className="clickable" onClick={() => navigate(ROUTES.PASSWORD_FORGOTTEN)}>{t('form.forgetPassword')}</div>
      {error && <p className="message message-warning">{error?.message}</p>}
      <button
        className="btn v-button primary"
        type="submit"
        disabled={isInvalid}
      >
        {t("form.signin")}
      </button>
      <GoogleSign />
    </Form>

    // <form onSubmit={handleSubmit} className="Form Form__Signin">
    //   <input
    //     type="text"
    //     name="email"
    //     placeholder="email"
    //     onChange={onChange}
    //     value={email}
    //   />
    //   <input
    //     type="password"
    //     name="password"
    //     placeholder="password"
    //     onChange={onChange}
    //     value={password}
    //   />
    //   <button className="btn text-white" type="submit" disabled={isInvalid}>
    //     Log in
    //   </button>
    //   <div
    //     className="btn v-button primary square-round"
    //     onClick={async () => {
    //       await doSignInGoogle();
    //     }}
    //   >
    //     Sign in with Google
    //   </div>
    //   {error && <p>{error.message}</p>}
    // </form>
  );
  // }
};

export default SignInPage;
