import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import SignOutButton from "../SignOut";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/auth";

const Navigation = () => {
  const { t } = useTranslation();
  // const firebase = useFirebase();
  const { user } = useAuth();
  const renderSignInOrSignUp = () => {
    return (
      <div className="nav-items-sign">
        <li className="item-simple">
          <Link to={ROUTES.SIGN_IN} className="item-simple">
            {t("auth.signin")}
          </Link>
        </li>
        <li>
          <Link className="item-button secondary" to={ROUTES.SIGN_UP}>
            {t("auth.signup")}
          </Link>
        </li>
      </div>
    );
  };
  return (
    <div>
      <ul className="nav-menu">
        {!user ? renderSignInOrSignUp() : <SignOutButton />}
      </ul>
    </div>
  );
};

export default Navigation;
