import { useLocation, Navigate } from "react-router-dom";
import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";
import {
  getAuth,
  UserCredential,
  User
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserLogin, UserSignUp } from "src/constants/types/User";
import { redirectResult, requestForgetPwd, signIn, signInWithGoogle, signOut, signUp } from "src/utils/auth";

export type UserContext =
  | {
    user: any;
    dispatch?: (user: any) => void;
    loading?: boolean;
  }
  | undefined;

export type AuthTypes = {
  user: User | null | undefined;
  loading?: boolean;
  userData?: any;
  setUserData?: (data: any) => void;
  signIn: ({ email, password }: UserLogin) => Promise<UserCredential | null>;
  signUp: ({
    email,
    password,
    username,
    phone,
  }: UserSignUp) => Promise<UserCredential | null>;
  signOut: () => void;
  signInWithGoogle: () => void;
  redirectResult: () => Promise<UserCredential | null>;
  requestForgetPwd: (email: string) => void
};

const defaultContext = {
  user: null,
  signIn,
  signUp,
  signOut,
  signInWithGoogle,
  redirectResult,
  requestForgetPwd,
};

export const AuthContext = createContext<AuthTypes>(defaultContext);

export function useAuth() {
  return useContext<AuthTypes>(AuthContext);
}
interface AuthProviderProps {
  children: ReactNode;
}

type AuthProviderType = React.FC<{ children: ReactNode }>;

export function RequireAuth({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => { }, [auth?.loading]);

  return auth?.loading ? (
    undefined
  ) : auth?.user ? (
    children
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
}

export const AuthProvider: AuthProviderType = ({
  children,
}: AuthProviderProps) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState(null);

  const value = {
    ...defaultContext,
    user,
    loading,
    userData,
    setUserData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
