// import t from "src/i18n";
import ButtonValidation from "@vc-components/commons/Button/Buttons";
import * as ROUTES from "src/constants/routes";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import OrlyTitle from "./OrlyTitleComponent";
import { useNavigate } from "react-router";
const OrlyLinkHome = () => {
  const price = "12.50";
  const currency = "€";
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <OrlyTitle
        title={t("orlylink.title")}
        tagline={t("orlylink.tagline", { price, currency })}
      />
      <div className="flex flex-jst-space-evenly buttons-section">
        <ButtonValidation
          type={"secondary"}
          className="mg-t-md"
          action={() => window.scrollTo(0, window.innerHeight)}
          label={t("button.discover")}
        />
        <ButtonValidation
          type={"primary"}
          action={() => navigate(ROUTES.BOOKORLYLINK)}
          buttonStyle="bg-color-yellow f-color-darkblue"
          shadow={true}
          className="mg-t-md"
          label={t("button.booking-sht")}
        />
      </div>
    </>
  );
};

export default OrlyLinkHome;
