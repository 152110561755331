import { ResponsePayment } from "./payment";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useEffect } from "react";
import { getSession } from "src/services/stripeServices";
const STATUS = {
  success: "success",
};
const PaymentStatus = ({ status, sessionId }: ResponsePayment) => {
  const { t } = useTranslation();
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await getSession(sessionId);
        const sessionData = await res.json();
        // Stripe.generateInvoice({ sessionData });
      } catch (error) {
        console.error(`Error while fetching Session`, error);
      }
    };
    if (status === STATUS.success) fetchSession();
  }, []);
  return (
    <div className="cadre_payment_status cadre_vc info">
      <span className="status-content">{t(`payment.${status}`)}</span>
      <span className="thanks-content">{t(`payment.thanks`)}</span>
      <div className="payment_footer">
        <span>{t("payment.info")}</span>
        <br />
        <span className="backBtn">{t("payment.backToHome")}</span>
      </div>
    </div>
  );
};


export default PaymentStatus