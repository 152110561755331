import { ReactNode } from "react";
type SectionsProps = {
  sectionTitle: string;
  children: ReactNode;
};

const Sections = ({ sectionTitle, children }: SectionsProps) => {
  return (
    <div className="cadre_sections">
      <h1 className="title">{sectionTitle}</h1>
      {children}
    </div>
  );
};

export default Sections;
