import React from "react";
import { useLocation } from "react-router";
import { getTokenId } from "src/store/persistedHelper";

import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const HEADERS_AUTORIZATION = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getTokenId(),
  },
});


 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}