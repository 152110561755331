export type stateMap = {
  [key: string]: trajectory | number
}
type trajectory = {
  place: string,
  address: string,
  coordinates: Array<number>,
  completed: boolean
}
export const trajetReducer = (
  state: stateMap = {
    startDate: Date.now(),
    depart: { place: "", address: "", coordinates: [], completed: false },
    arrive: { place: "", address: "", coordinates: [], completed: false },
  },
  action: any
) => {
  switch (action.type) {
    case "UPDATE_DATA_ROUTE":
      return { ...state, ...action.data };
    case "SET_IS_COMPLETED":
      const position: string = Object.keys(action.data)?.[0];
      const stateComplete: {} = state[position] || {}
      return {
        ...state,
        [position]: {
          ...(stateComplete),
          completed: action.data.completed,
        },
      };
    case "SET_DATA_ROUTE":
      return { ...action.data };
    case "REVERSE_ROUTE":
      return { depart: state.arrive, arrive: state.depart };
    default:
      return state;
  }
};
