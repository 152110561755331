import validation from "@vc-assets/images/check-circle.svg";
import cancel from "@vc-assets/images/cancel-icon.svg";

type ButtonValidationType = {
  type: "valid" | "cancel" | "primary" | "secondary" | "yellow";
  action?: () => void;
  shadow?: boolean | string;
  label?: string;
  className?: string;
  buttonStyle?: string;
};

const ButtonValidation = ({
  type,
  action,
  label,
  shadow,
  className,
  buttonStyle,
}: ButtonValidationType) => {
  return (
    <div className={`button ${className ? className : ""}`} onClick={action}>
      {type === "valid" || type === "cancel" ? (
        <img
          src={type === "valid" ? validation : type === "cancel" ? cancel : ""}
          alt={`check-${type}`}
        />
      ) : (
        <div className={`btn ${type} ${shadow ? "shadow " : ""}vc-rounded ${buttonStyle}`}>{label}</div>
      )}
    </div>
  );
};

export default ButtonValidation;
