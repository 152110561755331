import { ReactNode } from "react";
import { Navigate } from "react-router";
import * as ROUTES from "src/constants/routes";
import { useAuth } from "src/context/auth";
export const ProtectedRoute = ({
  children,
  redirectPath = ROUTES.SIGN_UP,
}: {
  children: ReactNode;
  redirectPath?: string;
}) => {
  const { user, userData, loading } = useAuth();
  if (loading) {
    return <div>...loading</div>;
  }
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  console.log("loading", userData);
  return <>{children}</>;
};
