
import { useTranslation } from "react-i18next";
import { useAuth } from "src/context/auth";

const SignOutButton = () => {
  const { signOut: doSignOut } = useAuth();
  const {t} = useTranslation();
  const signOut = () => {
    doSignOut?.();
  };

  return (
    <li onClick={() => signOut()} className="btn v-button secondary">
      {t('auth.signout')}
    </li>
  );
};

export default SignOutButton;
