import {
  getAuth,
  signInWithCustomToken,
  signOut as firebaseSignOut,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
} from "firebase/auth";
import * as apiService from "src/services/authSession";
import { UserLogin, UserSignUp } from "src/constants/types/User";

export const signUp = async ({
  email,
  password,
  username,
  phone,
  firstName,
  lastName,
}: UserSignUp) => {
  const { token } = await apiService.signUp({
    email,
    password,
    username,
    phone,
    firstName,
    lastName,
  });
  return await signInWithCustomToken(getAuth(), token);
};

export const signIn = async ({ email, password }: UserLogin) => {
  const auth = getAuth();
  const { token } = await apiService.signIn({
    email,
    password,
  });
  return await signInWithCustomToken(auth, token);
};

export const signInWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
};

export const redirectResult = async () => {
  const auth = getAuth();
  return await getRedirectResult(auth);
};

export const signOut = async () => {
  const auth = getAuth();
  return await firebaseSignOut(auth);
};

export const requestForgetPwd = async (email: string) => await apiService.requestForgetPwd(email);
