export const ME_ID_KEY = "me";
export const persistedUser = (object: string) =>
  localStorage.setItem(ME_ID_KEY, object);
export const removeTokenID = () => localStorage.removeItem(ME_ID_KEY);
export const getLocalMe = () => {
  const lsMe = localStorage.getItem(ME_ID_KEY);
  return lsMe ? JSON.parse(lsMe) : null;
};

export const getTokenId = () => {
  const lsMe = localStorage.getItem(ME_ID_KEY);
  return lsMe ? JSON.parse(lsMe).accessToken : null;
};
