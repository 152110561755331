import React, { useState } from "react";

import { useAuth } from "../../context/auth";

import * as apiService from "src/services/authSession";

import { createUserFromRedirect } from 'src/firebase/firebase'
import { Spinner } from "react-bootstrap";
import { persistedUser } from "src/store/persistedHelper";
import { useNavigate } from "react-router";
import { ERRORS } from "src/constants/Errors";

// import { PasswordChangeForm } from "../PasswordChange";
// import { PasswordForgetForm } from "../PasswordForget/PasswordForgetForm";
// import { withAuthorization } from "../Session/withAuthorization";
const AccountComponent = (props: any) => {
  // const [authUser, setUser] = useState({ ...initUser });
  const { user, loading, setUserData, redirectResult } = useAuth();
  const [name, setName] = useState<string | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          const { displayName, email, uid } = user;
          setName(displayName);
          // here is the code to try fetch all information of user data
          const userIdToken = await user.getIdToken();
          persistedUser(
            JSON.stringify({
              uid: uid,
              accessToken: userIdToken,
            })
          );
          const userRedirectCred = await redirectResult?.()
          try {
            const originInfoUser = {
              email,
            };
            let userData = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });
            // user sign in with redirect is already create
            // add it in database if not exist
            if (userRedirectCred && userData?.error?.code === ERRORS.USER.NOTFOUND) {
              delete userData.error;
              const { user: userCred } = userRedirectCred;
              const { displayName: nameCred, phoneNumber: phoneCred } = userCred
              await createUserFromRedirect({ displayName: nameCred, phone: phoneCred, uid })
              userData = await apiService.getUserData({
                userIdToken,
                userId: user.uid,
              });
            }
            setUserData?.({ ...userData, ...originInfoUser });
            setName(displayName);
          } catch (error) {
            console.log("error on getUserData", error);
          }
        } else {
          setName(null);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, setUserData]);

  return (
    <div>
      <span className="f-Circular text-capitalize text-white">
        {name ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("Profile")}
          >
            {name}
          </span>
        ) : (
          loading && <Spinner animation="border" role="status" size="sm" />
        )}
      </span>
    </div>
  );
};

export default AccountComponent;
