import {
  getTokenId,
  setTokenId,
  removeTokenID,
} from "src/store/persistedHelper";
import params from "src/config/params.json";
import * as firebase from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { API } from "src/constants/secret";

import { HEADERS_AUTORIZATION } from "src/utils/utils";

const config = {
  apiKey: params.REACT_APP_API_KEY,
  authDomain: params.REACT_APP_AUTH_DOMAIN,
  databaseURL: params.REACT_APP_DATABASE_URL,
  projectId: params.REACT_APP_PROJECT_ID,
  appId: params.REACT_APP_ID,
};

const app = firebase.initializeApp(config);

export const auth = getAuth();

export const getDb = () => getFirestore(app);

export default firebase;

export const register = async (paramsUser) => {
  try {
    const response = await fetch(API.url + "/API/Firebase/createUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(paramsUser),
    });
    return await response.json();
  } catch (error) {
    console.error("Error when sign up with email => ", error);
  }
};

export const login = async ({ email, password }) => {
  try {
    const response = await fetch(API.url + "/API/Firebase/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password: encodeURIComponent(password),
      }),
    });
    const resultJson = await response.json();
    return resultJson;
  } catch (error) {
    console.error("Error when sign in with email => ", error);
  }
};

export const doSignInGoogle = async (email, password) => {
  const providerGoogle = new auth.GoogleAuthProvider();
  if (providerGoogle)
    providerGoogle.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
  // const response = await fetch(`${API.url}/API/Firebase/getGoogleProvider`);
  // const { provider: providerGoogle } = (await response.json()) || {};
  return auth.signInWithRedirect(providerGoogle);
};

export const createUserFromRedirect = async (data) => {
  fetch(API.url + "/API/Firebase/createUserFromRedirect", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...HEADERS_AUTORIZATION().headers,
    },
    mode: "cors",
    body: JSON.stringify(data),
  });
};

export const getAuthGoogle = async () => {
  const { idToken, credential, ...rest } = await auth.getRedirectResult();
  const userIdToken = credential?.idToken || idToken;
  const headers = {
    ...HEADERS_AUTORIZATION(),
    "Access-Control-Allow-Origin": "*",
    token: userIdToken,
  };
  const user = await fetch(API.url + "/API/Firebase/signinGoogle", {
    method: "POST",
    headers,
  });
  return user;
};

export const getUserById = async (userId) => {
  try {
    const userData = await fetch(
      API.url + "/API/Firebase/Auth/getUserById?userId=" + userId,
      HEADERS_AUTORIZATION()
    );
    return userData.json();
  } catch (error) {
    console.error("Error while fetching user : ", error);
  }
};

export const requestForgetPassword = async (email) => {
  try {
    const resultForgetPassword = await fetch(
      API.url + "/API/Firebase/forgetPassword/", {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    console.log('result', await resultForgetPassword.json())
  } catch (error) {
    console.log('error', error)
  }
}

export const getMe = async () => {
  if (!getTokenId()) {
    console.error("No token id found");
    return;
  }
  try {
    const response = await fetch(API.url + "/API/Firebase/getme", {
      method: "GET",
      ...HEADERS_AUTORIZATION(),
    });
    const userData = await response.json();
    console.log("results sign with token", userData);
    if (userData.code === "auth/id-token-expired") {
      removeTokenID();
      return null;
    }
    return userData;
  } catch (error) {
    console.error("Error when sign in with token => ", error);
  }
};

// export const doSignOut = () => auth.signOut();
export const doSignOut = () => {
  removeTokenID();
};
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

export const updateUser = (data) => {
  const db = getDb();
  db.collection("users")
    .doc(auth.currentUser?.uid)
    .set({
      ...data,
    })
    .then(() => {
      console.info("successfully written to db");
    })
    .catch((err: any) => console.log(err));
};

export const updateUserProfile = async data => {
  try {
    const resp = await fetch(API.url + "/API/Firebase/updateme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...HEADERS_AUTORIZATION().headers,
      },
      mode: "cors",
      body: JSON.stringify(data),
    });
    const { user } = await resp.json()
    return user
  } catch (error) {

  }
}
