import { createRoot } from 'react-dom/client'
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import "./i18n/i18n";

import App from "./components/App";

import firebase from "./firebase/firebase";
import store from "./store";
import { AuthProvider } from "./context/auth";
import params from "src/config/params.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/scss/index.scss";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { BrowserRouter } from "react-router-dom";
// penser à ajouter react-i18next pour switch language

if (params.REACT_APP_NODE_ENV === "production")
  Sentry.init({
    dsn: "https://eba9eb20dbe04e5593c2e46fa28d6d3b@sentry.io/5176067",
  });

const rrfProps = {
  firebase,
  config: { userProfile: "user" },
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
};

const container = document.getElementById("root");
const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
