import { useTranslation } from "react-i18next";
import MainSection from "./Sections/MainSection";

import vtcPicto from "@vc-assets/images/vtc.svg";
import taxiPicto from "@vc-assets/images/taxi.svg";
import tramPicto from "@vc-assets/images/tram-v2.svg";
import busPicto from "@vc-assets/images/bus.svg";

import chrono from '@vc-assets/images/base.png';
import diamond from '@vc-assets/images/diamond.png';
import calendar from '@vc-assets/images/calendar.png'
import wallet from '@vc-assets/images/wallet.png'

type WinningCourseProps = {
  title: string;
  titleStyle?: string;
  containerStyle?: string;
};
const cardsCompare = [
  { name: "shuttle", picto: busPicto },
  { name: "vtc", picto: vtcPicto },
  { name: "publictransport", picto: tramPicto },
  { name: "taxi", picto: taxiPicto },
];

const cardsPros = [
  { name: "chrono", picto: chrono },
  { name: "calendar", picto: calendar },
  { name: "comfort", picto: diamond },
  { name: "wallet", picto: wallet, params: {price: "12,50"} },
]
const WinningCourseComponent = ({
  title,
  titleStyle,
  containerStyle,
}: WinningCourseProps) => {
  const { t } = useTranslation();
  return (
    <div className={containerStyle}>
      <span className={`tagline ${titleStyle}`}>{t("winningdrive.title")}</span>
      <hr className="split-title" />
      <MainSection cards={cardsCompare} containerStyle="bg-lightblue" />
      <span className={`tagline f-color-orange f-bold`}>{t("winningdrive.pros.title")}</span>
      <MainSection cards={cardsPros} containerStyle=""/>
    </div>
  );
};
export default WinningCourseComponent;
