import { Route, Routes } from "react-router-dom";

import MainContent from "./MainContent";
import * as ROUTES from "src/constants/routes";
import HomePage from "src/components/Home";
import ForgetPassword from "src/components/Account/ForgetPassword";
import SignUp from "src/components/SignUp";
import SignIn from "src/components/SignIn";
import CheckPayment from "src/components/Paiement/CheckPayment";
import Profile from "src/components/Profile";
import { ProtectedRoute } from "src/components/App/ProtectedRoute";
import BookFormComponent from "@vc-components/BookForm/BookFormcomponent";

const App = () => (
  <div className="App">
    <Routes>
      <Route path="/" element={<MainContent />}>
        <Route index element={<HomePage />} />
        <Route path={ROUTES.BOOKORLYLINK} element={<BookFormComponent />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.CHECK} element={<CheckPayment />} />
        <Route path={ROUTES.PASSWORD_FORGOTTEN} element={<ForgetPassword />} />
        <Route
          path={ROUTES.PROFILE}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  </div>
);

export default App