import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";
import { SlideCards } from "./SlideCards/SlideCards";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

register();
type cardData = {
  title: string;
  description: string;
};
const SwiperHome = ({ cards }: { cards?: cardData[] }) => {
  const swiperElRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    // listen for Swiper events using addEventListener
    // swiperElRef?.current?.addEventListener('swiperprogress', (e) => {
    //   const [swiper, progress] = e.detail;
    //   console.log(progress);
    // });
    // swiperElRef?.current?.addEventListener('swiperslidechange', (e) => {
    //   console.log('slide changed');
    // });
  }, []);
  return (
    <swiper-container
      ref={swiperElRef}
      slides-per-view={1}
      pagination="true"
      pagination-dynamic-bullets="true"
      navigation={(!isMobile).toString()}
    >
      {cards?.map((card: cardData, index) => {
        return (
          <SlideCards
            key={index}
            title={t(card.title)}
            textContent={t(card.description)}
          />
        );
      })}
    </swiper-container>
  );
};
export default SwiperHome;
