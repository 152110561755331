import { register } from "swiper/element/bundle";
import SwiperComponent from "./SwiperComponent";
import cards from "src/components/App/cards.json";
import OrlyTitle from "../HomeCards/OrlyTitleComponent";
import { useTranslation } from "react-i18next";
import ButtonValidation from "@vc-components/commons/Button/Buttons";
import { useNavigate } from "react-router";
import * as ROUTES from "src/constants/routes";

register();

const SwiperHome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="inline-center home-section">
      <div className="section-container">
        <OrlyTitle
          title={t("orlylink.title")}
          tagline={t("slides.supertitle")}
          className="f-white text-border"
          styleTagline="slide-big-title"
        />
        <hr className="split-title" />
        <SwiperComponent cards={cards} />
        <ButtonValidation
          className="mg-t-md"
          type="primary"
          buttonStyle="bg-color-yellow f-color-darkblue f-Montserrat"
          label={t("button.booking-lng")}
          shadow={true}
          action={() => {
            navigate(ROUTES.BOOKORLYLINK);
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </div>
  );
};
export default SwiperHome;
