import { API } from "src/constants/secret";
import { InvoiceDataType } from "src/constants/types/Courses";
import { HEADERS_AUTORIZATION } from "src/utils/utils";

const URL_API = API.url;
const createPaymentIntent = async (options: {}) => {
  const res = await fetch(
    URL_API + `/API/Stripe/services/create-payment-intent`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    }
  );

  if (res.status === 200) {
    const data = await res.json();
    return data.client_secret;
  }

  // console.log(res);
  throw new Error("PaymentIntent API Error");
};

const createCheckoutSession = async (options?: {}) => {
  const sessions = await fetch(`${URL_API}/API/Stripe/payment/session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(options),
  });

  return await sessions.json();
};

// get publish key stripe from server api
const getPublishKeyStripe = async (options?: {}) => {
  const res = await fetch(URL_API + "/API/Stripe/getPublishKey", {
    method: "GET",
    ...HEADERS_AUTORIZATION(),
  });
  try {
    if (res.status === 200) {
      const data = await res.json();
      return data.publicKey;
    }
  } catch (error) {
    console.log("API error:", { error });
    throw Error("API Error");
  }
};
type InfoStripe = {
  email: string;
  displayName: string;
  userId: string;
};

export const createUserStripe = async (data?: InfoStripe) => {
  const { email, displayName, userId } = data || {};
  const response = await fetch(
    URL_API + "/API/Stripe/customer/create-customer",
    {
      method: "POST",
      ...HEADERS_AUTORIZATION(),
      body: JSON.stringify({ userData: { email, name: displayName }, userId }),
    }
  );
  return await response.json();
};

const generateInvoice = async (data?: {}) => {
  return await fetch(URL_API + "/API/Stripe/payment/send-invoice", {
    method: "POST",
    ...HEADERS_AUTORIZATION(),
    body: JSON.stringify(data),
  });
};

export const downloadInvoice = async (data: InvoiceDataType) => {
  try {
    const resp = await fetch(URL_API + "/API/Stripe/payment/create-invoice", {
      method: "POST",
      ...HEADERS_AUTORIZATION(),
      body: JSON.stringify({ sessionData: data }),
    });
    const respBlob = await resp.blob();

    const url = window.URL.createObjectURL(new Blob([respBlob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `facture_${data.created}.pdf`); // or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
      console.error('Error downloading the file', error);
  }
};

export const getSession = async (sessionId?: string | null) => {
  return await fetch(`${URL_API}/API/Stripe/payment/get-session/${sessionId}`, {
    ...HEADERS_AUTORIZATION(),
  });
};

const getUserStripe = async ({ customerId }: { customerId: string }) => {
  return await fetch(URL_API + `/API/Stripe/customer/${customerId}`);
};

const getUserPaymentInfo = async (data?: {}) => {};

const api = {
  getPublishKeyStripe,
  createPaymentIntent,
  createUserStripe,
  generateInvoice,
  createCheckoutSession,
  getUserStripe,
};

export default api;
