type CardItemTypes = {
  label: string;
  textContent: string;
  picto: string;
  labelStyle?: string;
  cardContainerStyle?: string;
  contentStyle?: string;
};
const CardItemSection = ({
  label,
  textContent,
  labelStyle,
  cardContainerStyle,
  contentStyle,
  picto,
}: CardItemTypes) => {
  return (
    <div
      className={`flex flex-col flex-al-center flex-jst-center card-item-section ${cardContainerStyle}`}
    >
      <img src={picto} alt={`picto-${label}`} width={100} height={100} />
      <span className={`label-title f-Montserrat f-bold ${labelStyle}`}>
        {label}
      </span>
      <div
        className={`card-textContent f-Montserrat ${contentStyle}`}
        dangerouslySetInnerHTML={{ __html: textContent }}
      />
    </div>
  );
};
export default CardItemSection;
