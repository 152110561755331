import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };

  return (
    <div className="selectLanguage">
      <span
        className={i18n.language === 'fr' ? 'selected' : ''}
        onClick={() => changeLanguage('fr')}
      >
        fr&nbsp;
      </span>
      <span
        className={i18n.language === 'en' ? 'selected' : ''}
        onClick={() => changeLanguage('en')}
      >
        |&nbsp;en
      </span>
    </div>
  );
};

export default LanguageSelector;
