import "./styles.scss";

const FooterComponent: React.FC = () => {
  return (
    <div className="bg-color-darkblue f-color-white pd-v-sm pd-h-md f-Montserrat">
      <div className="">
        <span className="section-label f-bold">le service</span>
        <ul className="list-section">
          <li className="list-item">CGV / CGU</li>
          <li className="list-item">Foire aux questions</li>
          <li className="list-item">Les Mentions Légales</li>
        </ul>
      </div>
      <span className="legal-mention">Copyright © 2024 Vroomcab by M'Car'J / Adresse : 69, rue de la glacière , 75013 Paris, France / https://www.vroomcab.fr</span>
    </div>
  );
};

export default FooterComponent;
