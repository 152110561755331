import { ReactNode } from "react";

import "./styles.scss";
import CardItemSection from "./CardSection";
import { useTranslation } from "react-i18next";
type Card = {
  name: string;
  picto: string;
  params?: object;
};
type MainSectionProps = {
  containerStyle?: string;
  children?: ReactNode;
  cards?: Array<Card>;
};

const MainSection = ({ containerStyle, cards }: MainSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${containerStyle} grid-auto-fill grid`}>
      {cards?.map((card) => (
        <CardItemSection
          key={card.name}
          label={t(`winningdrive.cards.${card.name}.label`)}
          textContent={t(
            `winningdrive.cards.${card.name}.textContent`,
            card.params
          )}
          cardContainerStyle="pd-v-md pd-h-sm max-w-250"
          picto={card.picto}
        />
      ))}
    </div>
  );
};

export default MainSection;
