import { useState } from "react";
import OrlyTitle from "@vc-components/Home/HomeCards/OrlyTitleComponent";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { z } from "zod";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const formSchema = z.object({
  username: z
    .string()
    .min(2)
    .max(50),
  startDate: z.union([z.date(), z.string()]),
});

const BookFormComponent: React.FC = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      startDate: new Date(),
    },
  });

  const { setValue } = form;
  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
  }

  const handleChange = (dateChange: any) => {
    setValue("startDate", dateChange, {
      shouldDirty: true,
    });
    setDate(dateChange);
  };

  return (
    <div className="inline-center">
      <OrlyTitle title={t("orlylink.title")} />
      <p className="">{t("formule.instruction")}</p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <Controller
            control={form.control}
            name="startDate"
            defaultValue={date}
            render={({ field }) => (
              <div className="container-datepicker">
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value="2018-07-22"
                  min="2018-01-01"
                  max="2018-12-31"
                />
              </div>
            )}
          />
          <button className="btn btn-primary" type="submit">C'est parti</button>
        </form>
      </Form>
    </div>
  );
};

export default BookFormComponent;
