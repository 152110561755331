import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./local/fr.json";
import en from "./local/en.json";

i18n.use(initReactI18next).init({
  lng: "fr",
  resources: {
    fr: { translation: fr },
    en: { translation: en },
  },
  fallbackLng: "fr",
  debug: true,
  /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
  // defaultNS: "translation",
  interpolation: {
    escapeValue: false,
    //   formatSeparator: ",",
  },
  react: {
    defaultTransParent: 'div',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong','Slot'],
  },
});

export default i18n;
