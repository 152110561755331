import { useNavigate } from "react-router";
import { useAuth } from "src/context/auth";
import t from 'src/i18n'
const GoogleSign = () => {
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  return (<div
    className="btn"
    onClick={async () => {
      signInWithGoogle?.();
      navigate("/");
    }}
  >
    {t("sign.google")}
  </div>)
}

export default GoogleSign