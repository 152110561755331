import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/auth';
import './styles.scss'
const ForgetPassword = () => {
  const [email, setFormData] = useState('');
  const { t } = useTranslation()
  const { requestForgetPwd } = useAuth()
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    email &&
      requestForgetPwd(email)
  }
  return (
    <div className='cadre_vc form-simple'>
      <h2>{t('reset.password.title')}</h2>
      <p>{t('reset.password.notice')}</p>

      <Form className='form-content' onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            name="email"
            placeholder="email"
            onChange={(val) => setFormData(val.currentTarget.value)}
          />
        </Form.Group>
        <button className="btn v-button primary" type='submit'>{t('sendresetPassword')}</button>
      </Form>

    </div>
  )
}

export default ForgetPassword;