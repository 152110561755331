type ListItemsProps = {
  title?: string;
  className?: string;
  tabIndex: number;
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
};

const ListItems = ({
  title,
  className,
  setTabIndex,
  tabIndex,
}: ListItemsProps) => {
  return (
    <div
      className="vc-listItems"
      onClick={() => {
        setTabIndex?.(tabIndex);
      }}
    >
      <div className={`${className}`}>
        <span className="vc-listItems-content">{title}</span>
      </div>
      <hr />
    </div>
  );
};

export default ListItems;
