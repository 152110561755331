const OrlyTitle = ({
  title,
  tagline,
  className,
  styleTagline,
}: {
  title: string;
  tagline?: string;
  className?: string;
  styleTagline?: string;
}) => {
  return (
    <>
      <h1 className={"big-title " + className}>{title}</h1>
      {tagline && (
        <div
          className={`tagline pd-b-sm ${styleTagline}`}
          dangerouslySetInnerHTML={{
            __html: tagline,
          }}
        />
      )}
    </>
  );
};

export default OrlyTitle;
