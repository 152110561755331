import { useAuth } from "src/context/auth";
import "./styles.scss";
import Sections from "./subComponents/sections";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import stripeApi, { downloadInvoice } from "src/services/stripeServices";
import sectionsData from "./sections.json";
import ListItems from "./subComponents/listItems";
import { dateFormat } from "src/utils/date_utils";

import downloadIcon from "@vc-assets/images/download.svg";
import ButtonValidation from "@vc-components/commons/Button/Buttons";
import { updateUserProfile } from "src/firebase/firebase";

type profileType = {
  [key: string]: boolean | undefined;
};
type fieldValueType = {
  [key: string]: fieldValueObject;
};

interface fieldValueObject {
  value?: string;
  hasChanged: boolean;
}
interface UserRecord {
  email?: string;
  phoneNumber?: string | number;
  emailVerified?: boolean;
  password?: string;
  displayName?: string;
  photoURL?: string;
  disabled?: boolean;
}
interface Invoice {
  invoice_data: any;
}

interface CourseHistoryItem {
  id: string;
  created: number;
  invoice_creation: Invoice;
  status: string;
}

const Profile = () => {
  const { userData, setUserData } = useAuth();
  const { t } = useTranslation();
  const [coursesHistory, setCoursesHistory] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [editField, setEditField] = useState<profileType>({});
  const [fieldValue, setFieldValue] = useState<fieldValueType>({});

  const saveUser = async () => {
    console.log("userData", userData);
    if (Object.keys(fieldValue).length > 0) {
      const userUpdated = await updateUserProfile({
        displayName: fieldValue.displayName.value,
      });
      console.log("userUpdated,", userUpdated);
      setUserData?.((old: any) => ({ ...old, ...userUpdated }));
    }
  };
  useEffect(() => {
    (async () => {
      const { stripeCustomer } = userData || {};
      try {
        if (stripeCustomer) {
          const response = await stripeApi.getUserStripe({
            customerId: stripeCustomer,
          });
          const customer = await response?.json();
          const paymentIntents = customer.paymentIntentsList;
          setCoursesHistory(paymentIntents);
        }
      } catch (error) {
        console.log("error on getting customer stripe", error);
      }
    })();
  }, [userData]);

  const toggleEdit = (ev: React.SyntheticEvent<HTMLElement>) => {
    const fieldname = ev.currentTarget.dataset.name || "";
    const type = ev.type || "blur";
    if (fieldValue?.[fieldname]?.hasChanged) {
      setEditField((e) => ({
        ...e,
        [fieldname]: fieldValue?.[fieldname].value !== userData?.[fieldname],
      }));
    } else {
      setEditField((e) => ({ ...e, [fieldname]: type === "focus" }));
    }
  };

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldname = e.target.getAttribute("data-name") || "";
    setFieldValue?.((fn) => ({
      ...fn,
      [fieldname]: { value: e.target.value, hasChanged: true },
    }));
  };

  const handleDownloadInvoice = (data: CourseHistoryItem) =>
    downloadInvoice(data);

  return (
    <div className="grid-sections">
      <div className="cadre_vc left">
        {sectionsData.map(({ title }, index) => {
          return (
            <ListItems
              key={title + index}
              tabIndex={index}
              className={tabIndex === index ? "highlight" : ""}
              title={t(title)}
              setTabIndex={setTabIndex}
            />
          );
        })}
      </div>
      <div className="cadre_vc sections_vc">
        <h1>{t("profile.superTitle")}</h1>
        <div className="vc_sections-content">
          <div>
            {sectionsData.map(({ title, fields, values }, i) => {
              return (
                <Sections key={`${title}-${i}`} sectionTitle={t(title)}>
                  {fields ? (
                    fields.map((field, index) => {
                      return (
                        <div className="field" key={`${field}-${index}`}>
                          <div className="f_column">
                            <span className="name">{t(`form.${field}`)}</span>
                          </div>
                          <div className="f_column">
                            <input
                              className={`value${
                                editField[field] ? " edit" : " noedit"
                              }`}
                              onBlur={toggleEdit}
                              onFocus={toggleEdit}
                              readOnly={!editField[field]}
                              data-name={field}
                              onChange={onChangeField}
                              value={
                                fieldValue?.[field]?.value ||
                                userData?.[field] ||
                                ""
                              }
                              placeholder={t("form.noInfo")}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : values ? (
                    <table className="course-history">
                      <tbody>
                        <tr>
                          <th className="course-header">
                            {t("course.datetime")}
                          </th>
                          <th className="course-header">
                            {t("course.details")}
                          </th>
                          <th className="course-header">
                            {t("course.status")}
                          </th>
                          <th className="course-header">
                            {t("course.amount")}
                          </th>
                        </tr>
                        {(coursesHistory || []).map(
                          (val: CourseHistoryItem) => {
                            const { invoice_creation, status } = val;
                            const { invoice_data } = invoice_creation;
                            const { metadata } = invoice_data;
                            const {
                              datetime,
                              depart,
                              invoiceNumber,
                              price,
                              arrive,
                            } = metadata;
                            return val ? (
                              <tr key={val.id} className="course-history-item">
                                <td className="course-history-content datetime">
                                  {dateFormat(datetime / 1, "L")}
                                </td>
                                <td className="course-history-content details">
                                  <span>{`depart: ${depart}`}</span>
                                  <span>
                                    <br />
                                    {`arrivée: ${arrive}`}
                                  </span>
                                </td>
                                <td className="course-history-content status">
                                  <span>{status}</span>
                                </td>
                                <td className="course-history-content amount">
                                  <span>{Number(price).toFixed(2)} €</span>
                                </td>
                                <td
                                  className="vc_download"
                                  onClick={() => handleDownloadInvoice(val)}
                                >
                                  <img src={downloadIcon} alt="download-icon" />
                                </td>
                              </tr>
                            ) : null;
                          }
                        )}
                      </tbody>
                    </table>
                  ) : null}
                </Sections>
              );
            })}
          </div>
        </div>
        <div
          className={`btn v-button primary square-round submit`}
          onClick={saveUser}
        >
          {t("Profile.save")}
        </div>
      </div>
    </div>
  );
};

export default Profile;
