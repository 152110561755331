import {
  login,
  register,
  updateUser as updateUserService,
  requestForgetPassword
} from "../firebase/firebase";
import { API } from "src/constants/secret";
import { UserSignUp, UserLogin, User } from "src/constants/types/User";

// call function api firebase or API
// export const getAuthSignIn = (
//   userId: string | null | undefined,
//   callback: (e: object) => void
// ) => {
//   getDb()
//     .ref(`/users/${userId}`)
//     .on("value", (user) => {
//       let reponse = user.val();
//       console.log(user.toJSON());
//       reponse ? callback(user.val()) : callback({ username: "" });
//     });
// };

export const signIn = async (params: UserLogin) => {
  return await login(params);
};

export const signUp = async (params: UserSignUp) => {
  return await register(params);
};

export const getUserData = async ({ userIdToken, userId }: User) => {
  const url = `${API.url}/API/Firebase/getme/${userId}`;
  console.log(`userIdToken: ${userIdToken}`);
  try {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("error on fetch user data", error);
    return null;
  }
};

export const getAllUsers = async () => {
  const fetchUsers = await fetch(API.url + "/API/Firebase/getAllUsers");
  const users = await fetchUsers.json();
  return users;
};

export const updateUser = async (course: any) => {
  updateUserService(course);
};

export const requestForgetPwd = async (email: string) => requestForgetPassword(email)

export const updateProfile = async (user: any) => { };
