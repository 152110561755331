import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { trajetReducer } from "./trajetReducer";

// import { mapReducer } from './mapReducer'
// import { sessionReducer } from "./session";
// import { userReducer } from "./user";

export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  trajet: trajetReducer,
  // map : mapReducer,
  //   sessionState: sessionReducer,
  //   userState: userReducer
});

export type RootState = ReturnType<typeof rootReducer>;
