import OrlyLinkHome from "./HomeCards/OrlyLink";

const HomePage = () => {
  return (
    <div className="Container__Home">
      <OrlyLinkHome />
    </div>
  );
};

export default HomePage;
