import "./styles.scss";

export const SlideCards = ({
  title,
  textContent,
}: {
  title: string;
  textContent: string;
}) => {
  return (
    <swiper-slide lazy="false">
      <div className="slide-card">
        <div
          className="slide-super-title mg-bt-md pd-h-sm"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="slide-text-content"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
      </div>
    </swiper-slide>
  );
};
